<template>
    <div class="product-cart" @mouseover="onHover" @mouseleave="onLeave">
      <div class="product-cart-content">
        <a :href="'/product/'+ product.slug" class="product-cart-link">
          <div class="product-cart-image">
            <!-- Використовуємо тег Transition для анімації -->
            <Transition name="fade">
              <div class="image-wrapper" :class="{ 'blurred': selectedVariant?.stock === 0 }">
                <img :src="imageSrc" alt="" class="product-cart-img lazyloaded" key="imageSrc">
                <!-- Повідомлення, якщо товару немає в наявності -->
                <div v-if="selectedVariant?.stock === 0" class="out-of-stock-message">
                  Немає в наявності
                </div>
              </div>
          </Transition>
          </div>
        </a>
          <div class="product-cart-meta">
            <div class="product-cart-meta-main">
              <a :href="'/product/'+ product.slug" class="product-cart__title">{{ truncateText(product.name, 30) }}</a>
              <div class="product-cart__subtitle">{{ truncateText(product.human, 50) }}</div>

            </div>
            <div class="product-cart-meta-price">
              <span :class="{ 'crossed-price': selectedVariant?.discount_price > 0}"
                :style="{ color: isProductCollection ? 'black' : '' }"

                >
              {{ selectedVariant?.price }} грн
            </span>
            <span v-if="selectedVariant?.discount_price > 0">
              {{ selectedVariant?.discount_price }} грн
            </span>
            </div>      
            <div class="product-cart-meta-secondary">
              <div class="product-cart-copacity">
                <div v-if="product.variants.length > 1" class="variant-buttons">
                  <button 
                    v-for="(variant, index) in product.variants" 
                    :key="index" 
                    :class="{'selected-variant': selectedVariant.value === variant.value}" 
                    @click="selectVariant(variant)">
                    {{ variant.value }} {{ product.unit }}
                  </button>
                </div>
                <div v-else class="single-variant">
                  {{ selectedVariant.value }} {{ product.unit }}
                </div>
              </div>
            </div>
  
            <div class="product-action-wrapper">
              <button class="product-action" type="button" @click="addProdToCart(selectedVariant.id)">Додати в кошик</button>
              <button class="product-action" type="button" @click="addProdToWishlist(selectedVariant.id)">Додати в обране</button>
            </div>
          </div>

      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapState } from 'vuex';
  import '@/assets/css/product_cart.css';

  export default {
    name: "SliderItem",
    props: {
      product: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        selectedVariant: this.product.variants[0], // Початково вибраний перший варіант
        imageSrc: '', // Початкове зображення
        placeholder: '/media/content/free.png', // URL до заглушки
        isTransitioning: false, // Стан анімації переходу
        
      };
    },
    created() {
      if (this.selectedVariant.images[0]) {
        const imageUrl = this.selectedVariant.images[0].image;
        this.imageSrc = imageUrl.startsWith('http')
          ? imageUrl
          : `https://api.nichebeautylab.com.ua${imageUrl}`;
      } else {
        this.imageSrc = this.placeholder;
      }
    },

    computed: {
      ...mapState(['cart']),
      isProductCollection() {
        return this.$route.path.includes('/products_collection');
      }
    },
    
    methods: {
      ...mapActions('cart', ['addRemoveCart']), 
      ...mapActions('wishlist', ['addToWishList']), 
      truncateText(text, length) {
        if (!text) return ''; // Якщо текст відсутній, повертаємо порожній рядок
        return text.length > length ? text.substring(0, length) + '...' : text;
      },
      async addProdToCart(variantId, qty) {
        console.log('Adding to cart ID:', variantId, 'Quantity:', qty);
        this.$emit('pc_close_cart');
        try {
            this.addRemoveCart(variantId, qty);  // Передаємо ID варіанту і кількість, яку користувач вибрав
        } catch (error) {
            console.error("Error adding product to cart:", error);
      }},
     
      async addProdToWishlist(variantId) {
        this.$emit('pc_close_wish');
        try {
         
          this.addToWishList(variantId);
        } catch (error) {
          console.error("Error adding product variant to wishlist:", error);
        }
    },
      selectVariant(variant) {
        this.selectedVariant = variant;
        this.updateImage();
      },
  
      updateImage() {
        this.isTransitioning = true;
        setTimeout(() => {
          if (this.selectedVariant.images[0]) {
            const imageUrl = this.selectedVariant.images[0].image;
            this.imageSrc = imageUrl.startsWith('http')
              ? imageUrl
              : `https://api.nichebeautylab.com.ua${imageUrl}`;
          } else {
            this.imageSrc = this.placeholder;
          }
          this.isTransitioning = false;
        }, 300); // Час відповідає тривалості анімації
      },

  
      onHover() {
        this.isTransitioning = true;
        setTimeout(() => {
          if (this.selectedVariant.images[0]) {
            const imageUrl = this.selectedVariant.images[0].image;
            this.imageSrc = imageUrl.startsWith('http')
              ? imageUrl
              : `https://api.nichebeautylab.com.ua${imageUrl}`;
          } else {
            this.imageSrc = this.placeholder;
          }
          this.isTransitioning = false;
        }, 300); // Час відповідає тривалості анімації
      },
  
      onLeave() {
        this.isTransitioning = true;
        setTimeout(() => {
          if (this.selectedVariant.images[0]) {
            const imageUrl = this.selectedVariant.images[0].image;
            this.imageSrc = imageUrl.startsWith('http')
              ? imageUrl
              : `https://api.nichebeautylab.com.ua${imageUrl}`;
          } else {
            this.imageSrc = this.placeholder;
          }
          this.isTransitioning = false;
        }, 300); // Час відповідає тривалості анімації
      },
      
    },
  };
  </script>
