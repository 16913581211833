<template>
  <div class="app-version" v-if="showVersion">
    <p>Версія: {{ appVersion }}</p>
  </div>
</template>

<script>
export default {
  name: 'AppVersion',
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION || `DEV_${new Date().toISOString()}`,
      showVersion: process.env.NODE_ENV === 'development'
    }
  },
  mounted() {
    // Можна активувати відображення версії в консолі навіть для продакшену
    console.info(`App version: ${this.appVersion}`);
  }
}
</script>

<style scoped>
.app-version {
  position: fixed;
  bottom: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 10px;
  z-index: 9999;
}
</style> 