<template>
  <div id="app">
    <Navbar @toogle_cart="handleToggleCart" @toogle_wishlist="handleToggleWishlist"/>
    <CartDrawer  @close_cart="handleCloseeCart" :isCartVisible="isCartVisible"/>
    <WishList @close_wishlist="handleCloseeWishList" :isListVisible='isListVisible' @wh_close_cart="WhCloseCart"/>
    <main>
      <router-view @home_close_cart="AppCloseCart" @home_close_wish="AppCloseWish">
      </router-view>
    </main>
    <FooterComponent />
    <AppVersion />
  </div>
  
</template>

<script>
import Navbar from './components/NavbarComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import CartDrawer from './components/CartDrawer.vue';
import WishList from './components/WishList.vue';
import AppVersion from './components/AppVersion.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    FooterComponent,
    CartDrawer,
    WishList,
    AppVersion,
  },
  data() {
    return {
      isCartVisible: false,
      isListVisible: false
    };
  },
    mounted() {
      document.title = "Niche Beauty Lab";
    },
   methods: {
    handleToggleCart() {
      this.isCartVisible = !this.isCartVisible;
    },
    handleCloseeCart() {   
      this.isCartVisible = !this.isCartVisible;
    },
    handleToggleWishlist() {
      this.isListVisible = !this.isListVisible;
    },
    handleCloseeWishList() {
      this.isListVisible = !this.isListVisible;
    },
    AppCloseCart() {
      this.isCartVisible = !this.isCartVisible;
    },
    AppCloseWish() {
      this.isListVisible = !this.isListVisible;
    },
    WhCloseCart() {
      this.isListVisible = !this.isListVisible;
      this.isCartVisible = !this.isCartVisible;
    }
  }
};

</script>


