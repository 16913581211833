import axios from 'axios';
import Cookies from 'js-cookie';


console.log('API Base URL:', process.env.VUE_APP_API_BASE_URL);

// Створюємо екземпляр axios
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
});

// Єдиний приватний маршрут, де потрібен токен
const privateRoute = '/private-endpoint'; // Вкажіть тут ваш захищений маршрут

instance.interceptors.request.use(function (config) {
  const token = Cookies.get('authToken');

  // Якщо маршрут приватний і є токен, додаємо його до заголовка Authorization
  if (token && config.url.startsWith(privateRoute)) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

export default instance;
