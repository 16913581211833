<template>
    <BannerCarousel 
      v-if="bannerData[0]?.big_banner && is_active && !is_mobile"
      :banners="bannerData" 
    />
    <BannerCarousel 
      v-if="bannerData[0]?.big_banner_mobile && is_active && is_mobile"
      :banners="bannerData" 
      />

    <ProductCards style="margin-top: 20px;"
      v-if="collectionProducts.length && is_active"
      :products="collectionProducts" 
      @pc_s_close_cart="PTCloseCart" 
      @pc_s_close_wish="PTCloseWish" 
    />
</template>

<script>
import { ref, onMounted } from 'vue';
import instance from '@/api/axiosInstance';
import BannerCarousel from '@/components/BannerCarousel.vue';
import ProductCards from '@/components/ProductCards.vue';

export default {
  name: 'CollectionPageComponent',
  components: {
    BannerCarousel, 
    ProductCards,
  },
  methods: {
    PTCloseCart() {
      this.$emit('home_close_cart');
    },
    PTCloseWish() {
      this.$emit('home_close_wish');
    },
  },
  setup() {
    const bannerData = ref([]);
    const collectionProducts = ref([]);
    const is_active = ref(false);
    const fetchBannerData = async () => {
      try {
        const response = await instance.get('/api/v1/shop/collections/');
        const banner = response.data.collections[0].banner;
        const banner_mobile = response.data.collections[0].banner_mobile;
        bannerData.value = [{
          big_banner: banner,
          big_banner_mobile: banner_mobile
        }];
        is_active.value = response.data.collections[0].is_active; 
      } catch (error) {
        console.error('Error fetching banner data:', error);
      }
    };

    const fetchCollectionProducts = async () => {
      try {
        const response = await instance.get('/api/v1/shop/collection-products/');
        collectionProducts.value = response.data;
        console.log('collectionProducts:', collectionProducts.value);
      } catch (error) {
        console.error('Error fetching collection products:', error);
      }
    };

    onMounted(async () => {
      await Promise.all([
        fetchBannerData(),
        fetchCollectionProducts()
      ]);

    });

    return {
      bannerData,
      is_active,
      is_mobile: window.innerWidth < 768,
      collectionProducts
    };
  },
};
</script>